import React, { useState, useEffect, useMemo } from 'react';
import {
  Container,
  Row,
  Tabs,
  Tab,
  InputGroup,
  FormControl,
  Modal,
  Col,
  Nav,
} from 'react-bootstrap';
import { useTranslation } from 'react-i18next';
import { Link, useNavigate, useLocation } from 'react-router-dom';
import Slider from 'rc-slider';
import 'rc-slider/assets/index.css';
import { useDispatch, useSelector } from 'react-redux';
import Swal from 'sweetalert2';
import { toast } from 'react-toastify';
import { socket } from '../../web';
import { TVChartContainer } from '../Chart/Chart';
import { getCurrency } from '../../redux/currencies/currencyActions';
import {
  addLeverageOrder,
  clearLeverageOrder,
  getUserLeverageOrders,
  updateSocketOrder,
} from '../../redux/leverageOrder/leverageOrderActions';
import { getCoinToPrecision, getPairData } from '../../redux/pairs/pairActions';
import GetAccountData from '../GetAccountData';
import FullPageLoader from '../FullPageLoader/fullPageLoader';
import { getAccount, getUserTotalBalance } from '../../redux/account/accountActions';
import TradePriceWidget from '../TradePriceWidget';
import { Order } from '..';
import WalletTransfer from '../WalletTransfer';
import TradeOrdersDatatables from '../TradePagesData';
import { getLeverageByCurrency } from '../../redux/leverage/leverageActions';
import { convertAmountToUSD, nonNegativeAmount } from '../../redux/apiHelper';
import { getLeverageValue } from '../../redux/leverageSetting/leverageSettingActions';
import useMediaQuery from '../../hooks/useMediaQuery';
import { toFixed, setDynamicPrecision } from '../../helpers/utils';
import { isHighValuePair } from './helper';
import SetLeverageModal from './LeverageWidget/SetLeverageModal';
import { MarginType } from '../../types/order';
import { SystemSettingsTypes } from '../../types/systemSettings';
import { getSystemSettings } from '../../redux/systemSettings/systemSettingsActions';
import CustomToastBody from './CustomToastBody/CustomTostBody';
import useTradingStatusToast from '../../hooks/useTradingStatusMessage';

let localOrderBook = [];
let futures = [];
let futuresCounter = 0;

function TradeInfo({ symbol, today, unsubscribeFromPrevSocket }) {
  const { t } = useTranslation();
  const { pathname } = useLocation();
  const token = localStorage.getItem('uToken');
  const isMobile = useMediaQuery(768);
  const [, setCrSymbol] = useState('ETHUSDT');
  const [percentage, setPercentage] = useState(`${0}%`);
  const [coinAmountQty, setCoinAmountQty] = useState('');
  const [coinAmountCost, setCoinAmountCost] = useState('');
  const [buyingRate, setBuyingRate] = useState(0);
  const [sellingRate, setSellingRate] = useState(0);
  const [rate, setRate] = useState(true);
  const [error, setError] = useState('');
  const [primAvbl, setPrimAvbl] = useState(0);
  const [secAvbl, setSecAvbl] = useState(0);
  const [creditAvailable, setCreditAvailable] = useState(0);
  const [initAvbl, setInitAvbl] = useState(0);
  const [primaryCoin, setPrimaryCoin] = useState('ETH');
  const [secondaryCoin, setSecondaryCoin] = useState('USDT');
  const [toggle, setToggle] = useState(1); // 1=buy, 0=sell
  const [market, setMarket] = useState(0); // 2=conditional 1=market 0=limit
  const [conditionalMarket, setConditionalMarket] = useState(1); // 2=conditional 1=market 0=limit
  const [TPSL, setTPSL] = useState(false);
  const [TP, setTP] = useState(0);
  const [SL, setSL] = useState(0);
  const [margin, setMargin] = useState(MarginType.ISOLATED); // 0=cross, 1=isolated
  const [byQty, setByQty] = useState(1); // 0=By Cost, 1=By Qty
  const [showLeverage, setShowLeverage] = useState(false);
  const [loader, setLoader] = useState(false);
  const [selectedRow, setSelectedRow] = useState('');
  const [trailingPrice, setTrailingPrice] = useState(0);
  const [maintenanceMargin, setMaintenanceMargin] = useState(0);
  const [maintenanceAmount, setMaintenanceAmount] = useState(0);
  const [isTradingEnabled, setIsTradingEnabled] = useState(true);

  const [orderBookAPI, setOrderBookAPI] = useState([]);
  const [futureTradesAPI, setFutureTradesAPI] = useState([]);
  const [pairName, setPairName] = useState('');
  const [currentMarketPrice, setcurrentMarketPrice] = useState(0);
  const [ticketPrice, setTickerPrice] = useState(0);

  const [coins, setCoins] = useState([]);
  const [coinsToPrecisions, setCoinsToPrecisions] = useState({});
  const [isBtnDisabled, setIsBtnDisabled] = useState(true);
  const [isLeverageLoading, setIsLeverageLoading] = useState(false);
  const [leverage, setLeverage] = useState(1);

  const history = useNavigate();
  const dispatch = useDispatch();
  const { _id: userId, isWithdrawalEnabled } = useSelector((state) => state.user?.user);
  const currencyData = useSelector((state) => state.currency?.currencies?.allCurrencies);
  const {
    amounts, previousTotalCredit, totalAvailableCredit,
  } = useSelector((state) => state.accounts?.account);
  const totalBalance = useSelector((state) => state.accounts.totalBalance);
  const creationError = useSelector((state) => state.LeverageOrders?.error);
  const success = useSelector((state) => state.LeverageOrders?.success);
  const stopped = useSelector((state) => state.LeverageOrders?.stopped);
  const started = useSelector((state) => state.LeverageOrders?.started);
  const leverageLimits = useSelector((state) => state.leverages?.leverage?.leverages);
  const userOrders = useSelector((state) => state.LeverageOrders?.userOrders);
  const coinToPrecision = useSelector((state) => state.pairTrade?.coinToPrecision);
  const { pair } = useSelector((state) => state.pairTrade);
  const leverageData = useSelector((state) => state.leverageSettings.leverage);
  const isLeverageChangeEnabled = useSelector((state) => state.systemSettingsReducer.systemSettings.find((setting) => setting.name === SystemSettingsTypes.IS_LEVERAGE_CHANGE_ENABLED)?.value);
  const account = useSelector((state) => state?.accounts?.account);

  const [showConfirmation, setShowConfirmation] = useState(false);
  const handleCloseConfirmation = () => setShowConfirmation(false);

  const chartComponent = useMemo(() => (symbol ? <TVChartContainer pair={symbol} market="futures" /> : null), [symbol]);

  const handleShowConfirmation = async () => {
    if (!isTradingEnabled) {
      toast.error(t('messages.trading_disabled'));
      return;
    }
    
    if (isBtnDisabled || error.length) {
      toast.error(t('messages.invalid_order_volume'));
      return;
    }
  
    const allowedAmount = await convertAmountToUSD(primaryCoin.symbol);
    const amount = byQty === 1 ? coinAmountQty : coinAmountCost;
  
    const showAlert = (message) => Swal.fire({
      text: message,
      icon: 'info',
      showCancelButton: false,
      confirmButtonText: 'OK',
    });
  
    const checkVolumeLimits = () => {
      if (pair.minVolume !== 0 && amount < pair.minVolume) {
        return showAlert(`Min volume ${pair.minVolume}`);
      }
      if (pair.maxVolume !== 0 && amount > pair.maxVolume) {
        return showAlert(`Max volume ${pair.maxVolume}`);
      }
      return null;
    };
  
    const span = leverageLimits.find(
      (row) => row.fromAmount <= coinAmountCost && row.toAmount >= coinAmountCost,
    );
  
    if (pair.minVolume !== 0 || pair.maxVolume !== 0) {
      const volumeAlert = checkVolumeLimits();
      if (volumeAlert) return volumeAlert;
    }
  
    const isInvalidOrder = () => (
      leverage <= 0
      || !coinAmountQty
      || coinAmountQty < allowedAmount
      || parseFloat(buyingRate) <= 0
      || parseFloat(sellingRate) <= 0
      || secAvbl <= 0
    );
  
    if (span?.leverage < leverage) {
      showAlert(t('messages.max_leverage_warning', { leverage: `${span.leverage}` }));
      return;
    }

    if (isInvalidOrder()) {
      showAlert(t('messages.trade_zero_rate_warning', { min_coin_amount: `${allowedAmount} ${primaryCoin.symbol}` }));
      return;
    }

    setMaintenanceMargin(span?.maintenanceMR || 0);
    setMaintenanceAmount(span?.maintenanceAmount || 0);
    setShowConfirmation(true);
  };

  const handleCloseLeverage = () => setShowLeverage(false);
  const handleShowLeverage = () => setShowLeverage(true);
  const handleSystemSettingsCreateOrUpdate = async () => {
    await dispatch(getSystemSettings());
  };


  const handleUpdateLeverageOrderRes = (data) => {
    Promise.all([
      dispatch(updateSocketOrder(data)),
      dispatch(getAccount(userId)),
      dispatch(getUserTotalBalance()),
    ]);
  };

  const memoizedSetLeverageModal = useMemo(() => (
    <SetLeverageModal
      show={showLeverage}
      onHide={handleCloseLeverage}
      margin={margin}
      setMargin={setMargin}
      leverage={leverage}
      setLeverage={setLeverage}
      isLeverageChangeEnabled={isLeverageChangeEnabled}
    />
  ), [showLeverage, margin, leverage, isLeverageChangeEnabled]);

  useEffect(() => {
    if (account?.isTradingEnabled !== undefined) {
      setIsTradingEnabled(account.isTradingEnabled);
    }
  }, [account.isTradingEnabled]);
  
  useTradingStatusToast(isTradingEnabled);

  useEffect(() => {
    socket.on('receiveSystemSettingsUpdate', () => {
      dispatch(getSystemSettings());
    });
    socket.on('receiveSystemSettingsCreate', () => {
      dispatch(getSystemSettings());
    });
  }, [socket]);

  useEffect(() => {
    dispatch(getCoinToPrecision('future'));
    dispatch(getSystemSettings());
  }, []);

  useEffect(() => {
    if (isLeverageChangeEnabled) {
      setLeverage(1);
      return;
    }
    if (leverageData) {
      setLeverage(leverageData);
    }
  }, [isLeverageChangeEnabled, leverageData]);

  useEffect(() => {
    const selectedCoin = pathname.split('/')[2];
    if (selectedCoin) {
      dispatch(getPairData(selectedCoin));
    }
  }, [pathname]);

  useEffect(() => {
    if (socket && userId) {
      socket.on(`onUpdateLeverageOrderRes${userId}`, handleUpdateLeverageOrderRes);
      return () => {
        socket.off(`onUpdateLeverageOrderRes${userId}`);
      };
    }
  }, [socket, userId]);

  useEffect(() => {
    if (coinToPrecision) {
      setCoins(Object.keys(coinToPrecision));
      setCoinsToPrecisions(coinToPrecision);
    }
  }, [coinToPrecision]);

  useEffect(() => {
    if (pair && pair.isEnabled && socket && userId && today) {
      socket.emit('getBinanceFutureTradesRequest', { pairName: pair.name, userId, today });
      socket.on(`getBinanceFutureTradesRequestResponse${pair.name}${userId}${today}`, (response) => {
        futuresCounter += 1;
        if (futuresCounter % 20 === 0) {
          futures.unshift(response);
          if (futures.length > 20) futures = futures.splice(0, 20);
          setFutureTradesAPI(futures);
          futuresCounter = 0;
        }
      });

      socket.emit('getCurrentMarketPriceRequest', { pairName: pair.name, userId, today });
      socket.on(`getCurrentMarketPriceResponse${pair.name}${userId}${today}`, (currentMarketPriceData) => {
        setcurrentMarketPrice(currentMarketPriceData);
      });
      
      socket.emit('getBinanceMarketDepthRequest', { pairName: pair.name, userId, today });
      socket.on(`getBinanceMarketDepthRequest${pair.name}${userId}${today}`, (response) => {
        if (localOrderBook && localOrderBook.a?.length && localOrderBook.b?.length) {
          const data = {
            a: (response.a?.filter((row) => (parseFloat(row['1']) > 0)).concat(localOrderBook?.a))?.splice(0, 15),
            b: (response.b?.filter((row) => (parseFloat(row['1']) > 0)).concat(localOrderBook?.b))?.splice(0, 15),
          };
          localOrderBook = data;
          setOrderBookAPI(data);
        } else {
          const data = {
            a: [...(response.a.filter((row) => (parseFloat(row['1']) > 0)))],
            b: [...(response.b.filter((row) => (parseFloat(row['1']) > 0)))],
          };
          localOrderBook = data;
          setOrderBookAPI(data);
        }
      });
    }

    return () => { 
      unsubscribeFromPrevSocket(pairName);
    };
  }, [socket, pair, userId, today]);

  useEffect(() => {
    if (userId) {
      Promise.all([
        dispatch(getUserLeverageOrders(userId)),
        dispatch(getAccount(userId)),
      ]);
    }
    Promise.all([
      dispatch(getCurrency()),
      dispatch(getUserTotalBalance()),
    ]);
  }, [userId]);

  useEffect(async () => {
    if (pairName && userId) {
      setIsBtnDisabled(true);
      setIsLeverageLoading(true);
      await dispatch(getLeverageValue({ userId, pairName }));
      setIsLeverageLoading(false);
    }
  }, [pairName, userId]);

  useEffect(async () => {
    if (success) {
      await Promise.all([
        dispatch(getAccount(userId)),
        dispatch(clearLeverageOrder()),
        dispatch(getUserTotalBalance()),
      ]);

      setLoader(false);
      setTPSL(false);
      setTP('');
      setSL('');
      setCoinAmountQty(0);
      setCoinAmountCost(0);
      setPercentage(`${0}%`);
      setMargin(1);
      setIsBtnDisabled(false);
    }
  }, [success]);

  useEffect(() => {
    if (creationError) {
      if (userId) {
        Promise.all([
          dispatch(getUserLeverageOrders(userId)),
          dispatch(getAccount(userId)),
          dispatch(getUserTotalBalance()),
        ]);
      }
      dispatch(clearLeverageOrder());
      setIsBtnDisabled(false);
      setLoader(false);
    }
  }, [creationError]);

  useEffect(() => {
    if (stopped) {
      dispatch(clearLeverageOrder());
    }
  }, [stopped]);

  useEffect(() => {
    if (started) {
      if (userId) {
        dispatch(getUserLeverageOrders(userId));
      }
      dispatch(clearLeverageOrder());
    }
  }, [started]);

  // Implement socket
  useEffect(() => {
    if (rate && primaryCoin?.symbol && secondaryCoin?.symbol && currentMarketPrice) {
      setBuyingRate(
        parseFloat(
          currentMarketPrice
            ? currentMarketPrice?.find(
              (row) => row.symbol === (primaryCoin?.symbol || 0) + (secondaryCoin?.symbol || 0),
            ).markPrice
            : 0,
        ),
      );
      setSellingRate(
        parseFloat(
          currentMarketPrice
            ? currentMarketPrice?.find(
              (row) => row.symbol === (primaryCoin?.symbol || 0) + (secondaryCoin?.symbol || 0),
            )?.markPrice
            : 0,
        ),
      );
      setRate(false);
      setIsBtnDisabled(false);
    }
  }, [rate, primaryCoin, currentMarketPrice]);

  const findAmount = (coinId) => parseFloat(nonNegativeAmount(amounts.find((row) => row.currencyId === coinId)?.futures_amount));
  const findCreditAmount = (coinId) => parseFloat(nonNegativeAmount(amounts.find((row) => row.currencyId === coinId)?.credit_amount));

  const initializeData = () => {
    setCoinAmountQty(0);
    setCoinAmountCost(0);
    setRate(true);
    setPairName(`${primaryCoin.symbol}${secondaryCoin.symbol}`);
    dispatch(getLeverageByCurrency(primaryCoin._id));

    if (amounts) {
      setPrimAvbl(findAmount(primaryCoin._id));
      setSecAvbl(findAmount(secondaryCoin._id));
      setCreditAvailable(findCreditAmount(primaryCoin._id));
      setInitAvbl(findAmount(secondaryCoin._id));
    }
  };
  
  useEffect(() => {
    if (primaryCoin?.symbol && secondaryCoin?.symbol) {
      initializeData();
    }
  }, [secondaryCoin, primaryCoin]);

  useEffect(() => {
    if (today) {
      setcurrentMarketPrice(null);
      setRate(true);
    }
  }, [today]);

  const defaultAssignment = () => {
    setCrSymbol('ETHUSDT');
    const prim = currencyData?.find((row) => row.symbol === 'ETH');
    setPrimaryCoin(prim);
    const sec = currencyData?.find((row) => row.symbol === 'USDT');
    setSecondaryCoin(sec);
    if (amounts) {
      setPrimAvbl(findAmount(prim._id));
      setSecAvbl(findAmount(sec._id));
      setInitAvbl(findAmount(sec._id));
    }
  };

  useEffect(() => {
    if (currencyData) {
      const parseUriSegment = window.location.pathname.split('/');
      if (parseUriSegment[2] && coins.includes(parseUriSegment[2])) {
        setCrSymbol(parseUriSegment[2]);
        const a = parseUriSegment[2].substring(0, parseUriSegment[2].length - 4);
        const prim = currencyData?.find((row) => row.symbol === a);
        if (!prim) {
          defaultAssignment();
          return;
        }
        setPrimaryCoin(prim);
        const b = parseUriSegment[2].substring(
          parseUriSegment[2].length - 4,
          parseUriSegment[2].length,
        );
        const sec = currencyData.find((row) => row.symbol === b);
        if (!sec) {
          defaultAssignment();
          return;
        }
        setSecondaryCoin(sec);
        if (amounts) {
          setPrimAvbl(findAmount(prim._id));
          setSecAvbl(findAmount(sec._id));
          setInitAvbl(findAmount(sec._id));
        }
      } else {
        defaultAssignment();
      }
    }
  }, [history, currencyData, amounts]);

  const percentageMarks = {
    0: '0%',
    25: '25%',
    50: '50%',
    75: '75%',
    100: '100%',
  };

  const calculateSecondaryOrderValue = useMemo(() => {
    const currentRate = toggle === 0 ? buyingRate : sellingRate;
    const orderValue = (byQty ? coinAmountQty * currentRate : coinAmountCost) * leverage;

    return toFixed(orderValue, setDynamicPrecision(orderValue));
  }, [coinAmountCost, coinAmountQty, buyingRate, sellingRate, byQty, leverage]);

  const calculatePrimaryOrderValue = useMemo(() => {
    const currentRate = toggle === 0 ? buyingRate : sellingRate;
    const orderValue = (byQty ? coinAmountQty : (coinAmountCost / currentRate)) * leverage;

    return toFixed(orderValue, setDynamicPrecision(orderValue));
  }, [coinAmountCost, coinAmountQty, buyingRate, sellingRate, byQty, leverage]);

  const handleSubmit = async () => {
    if (!isTradingEnabled) {
      toast.error(t('messages.trading_disabled'));
      return;
    }

    let currentBuyingRate = null;
    let currentSellingRate = null;

    if (!(market === 0 || (market === 2 && conditionalMarket === 0))) {
      currentBuyingRate = parseFloat(
        currentMarketPrice
          ? currentMarketPrice?.find(
            (row) => row.symbol === (primaryCoin?.symbol || 0) + (secondaryCoin?.symbol || 0),
          ).markPrice
          : 0,
      );

      currentSellingRate = parseFloat(
        currentMarketPrice
          ? currentMarketPrice?.find(
            (row) => row.symbol === (primaryCoin?.symbol || 0) + (secondaryCoin?.symbol || 0),
          )?.markPrice
          : 0,
      );
    }

    const orderQty = calculatePrimaryOrderValue;

    const data = {
      userId,
      pairName: (primaryCoin?.symbol || '') + secondaryCoin.symbol,
      toCurrency: primaryCoin?._id,
      fromCurrency: secondaryCoin?._id,
      tpsl: TPSL,
      takeProfitPrice: parseFloat(TP),
      stopLossPrice: parseFloat(SL),
      tradeType: toggle,
      leverage: parseFloat(leverage),
      marginType: margin,
      tradeStartPrice: toggle === 1 ? parseFloat(currentBuyingRate || buyingRate) : parseFloat(currentSellingRate || sellingRate),
      userInvestedAmount: parseFloat(coinAmountCost),
      qty: parseFloat(orderQty),
      marketOrder: parseFloat(market),
      futuresOrder: 1,
      trailingPrice: parseFloat(trailingPrice),
      maintenanceMargin,
      maintenanceAmount,
      currentBuyRate: parseFloat(currentBuyingRate || buyingRate),
      currentSellRate: parseFloat(currentSellingRate || sellingRate),
    };

    setIsBtnDisabled(true);
    await dispatch(addLeverageOrder(data));
    handleCloseConfirmation();
  };

  const handlePercentLimit = (val, per = 0) => {
    if (secAvbl) {
      const validNumber = (/^\d*\.?\d*$/);
      if (
        !val.replace('%', '').toString().match(validNumber)
        || parseFloat(val) > 100
        || parseFloat(val) < 1
        || parseFloat(parseFloat(val) / parseFloat(sellingRate)) > parseFloat(100)
        || parseFloat(parseFloat(val) / parseFloat(sellingRate)) < parseFloat(0)
      ) {
        Swal.fire({
          text: `${t('messages.invalid_number_warning')}`,
          icon: 'info',
          showCancelButton: false,
          confirmButtonText: 'OK',
        });
        if (per) {
          setCoinAmountQty(0);
          setCoinAmountCost(0);
          setPercentage(`${0}%`);
        }
      }
    }
  };

  // const padTo2Digits = (num) => num.toString().padStart(2, '0');

  const sliderStyles = isBtnDisabled
    ? { backgroundColor: 'transparent' }
    : {};

  const handleAmountChange = (val, fromRange = false) => {
    let value = 0;
    if (!fromRange && byQty === 0) {
      // val is in usdt -> change it to eth
      value = val / (toggle === 0 ? parseFloat(buyingRate) : parseFloat(sellingRate));
    } else {
      value = val;
    }
    setError('');
    if (secAvbl) {
      const pr = ((!fromRange && byQty === 0 ? val : value * parseFloat(sellingRate)) * 100)
        / parseFloat(secAvbl);
      setPercentage(`${pr}%`);
      if (pr > 100 || pr < 0) {
        setError(`${t('messages.insufficient_balance_warning')}`);
      }
      const pairName = pathname.split('/')[2];
      const amountCostValue = !fromRange && byQty === 0 ? val : (value * parseFloat(sellingRate));

      const valuePair = isHighValuePair(pairName) && byQty === 1;
      const costValue = valuePair ? amountCostValue : toFixed(amountCostValue, 2);
      const qtyValue = valuePair ? value : toFixed(value, 2);

      setCoinAmountCost(costValue);
      setCoinAmountQty(qtyValue); 
    } else {
      setError(`${t('messages.insufficient_balance_warning')}`);
    }
  };

  function rangeValue(value) {
    const val = value;
    setPercentage(`${val}%`);
    if (secAvbl) {
      handleAmountChange((secAvbl * (val / 100)) / parseFloat(sellingRate), true);
    } else {
      setError(`${t('messages.insufficient_balance_warning')}`);
    }
  }

  const handleOrderPriceChange = (e) => {
    let currentVal = 0;
    if (e?.target?.value) {
      currentVal = e?.target?.value;
    }
    setBuyingRate(currentVal);
    setSellingRate(currentVal);
    rangeValue(percentage.replace('%', ''));
  };

  const ordertabs = (
    <div className="buy-tabs">
      <div className="flex-row mb-2 text-white d-flex justify-content-evenly">
        <div className={market === 0 ? 'text-green point' : 'point'} onClick={() => setMarket(0)}>
          {t('labels.limit')}
        </div>
        <div className={market === 1 ? 'text-green point' : 'point'} onClick={() => setMarket(1)}>
          {t('labels.market')}
        </div>
        {toggle === 1 ? (
          <div className={market === 2 ? 'text-green point' : 'point'} onClick={() => setMarket(2)}>
            {t('labels.conditional')}
          </div>
        ) : null}
      </div>
      {market === 2 ? (
        <div className="">
          <span>{t('labels.trigger_price')}</span>
          <InputGroup className="mb-4">
            <FormControl
              placeholder={t('labels.rate')}
              aria-label=""
              aria-describedby=""
              value={toggle === 1 ? buyingRate : sellingRate}
              onChange={(e) => {
                setBuyingRate(e.target.value);
                setSellingRate(e.target.value);
              }}
            />
            <InputGroup.Text
              className="point"
              onClick={() => {
                setRate(true);
              }}
            >
              +/-
            </InputGroup.Text>
          </InputGroup>
          <Tabs defaultActiveKey="home" id="uncontrolled-tab-example" className="mb-3">
            <Tab eventKey="home" title="Last">
              <p />
            </Tab>
            <Tab eventKey="profile" title="Index">
              <p />
            </Tab>
            <Tab eventKey="contact" title="Mark">
              <p />
            </Tab>
          </Tabs>
          <Tab.Container id="left-tabs-example" defaultActiveKey="first">
            <Row>
              <Col sm={12}>
                <Nav variant="pills" className="">
                  <Nav.Item>
                    <Nav.Link
                      className={conditionalMarket === 1 ? 'market-border' : ''}
                      onClick={() => setConditionalMarket(1)}
                      eventKey="first"
                    >
                      {t('labels.market')}
                    </Nav.Link>
                  </Nav.Item>
                  <Nav.Item>
                    <Nav.Link
                      className={conditionalMarket === 0 ? 'market-border' : ''}
                      onClick={() => setConditionalMarket(0)}
                      eventKey="second"
                    >
                      {t('labels.limit')}
                    </Nav.Link>
                  </Nav.Item>
                </Nav>
              </Col>
            </Row>
          </Tab.Container>
        </div>
      ) : (
        ''
      )}
      {/* Conditional Code end */}

      <div className="mt-2 mb-2">
        <p style={{ fontSize: 'smaller' }}>
          <u>{`${t('labels.available_balance')}:`}</u>
          {token ? `${toFixed(secAvbl, 2)} ${secondaryCoin.symbol}` : '--'}
        </p>
      </div>

      {market === 0 || (market === 2 && conditionalMarket === 0) ? (
        <>
          <span className="text-white">{t('labels.order_price')}</span>
          <InputGroup className="mb-4">
            <FormControl
              type="number"
              placeholder={t('labels.rate')}
              aria-label=""
              aria-describedby=""
              disabled={isBtnDisabled}
              value={isBtnDisabled ? 0 : (toggle === 1 ? toFixed(buyingRate, 2) : toFixed(sellingRate, 2))}
              onChange={handleOrderPriceChange}
            />
            <InputGroup.Text
              className="point"
              onClick={() => {
                setRate(true);
              }}
            >
              +/-
            </InputGroup.Text>
          </InputGroup>
        </>
      ) : null}

      <span className="text-white">
        {t('labels.order_by')}
        {byQty ? 'Qty' : 'Cost'}
      </span>
      <InputGroup className="mb-3">
        <FormControl
          type="number"
          step="0.1"
          placeholder={t('labels.price')}
          min="0.0"
          max={(toggle === 1 ? secAvbl : primAvbl) ? (toggle === 1 ? secAvbl : primAvbl) : 10000000}
          value={byQty ? coinAmountQty : coinAmountCost}
          onChange={(e) => {
            handleAmountChange(e.target.value);
          }}
        />
        <div className="dropdown order-by-currency-coin">
          <button
            className="btn text-white dropdown-toggle d-flex align-items-center"
            type="button"
            id="dropdownMenuButton1"
            data-bs-toggle="dropdown"
            aria-expanded="false"
          >
            <p>{byQty ? primaryCoin?.symbol : secondaryCoin?.symbol}</p>
          </button>
          <ul className="dropdown-menu" aria-labelledby="dropdownMenuButton1">
            <li>
              <a className="dropdown-item">
                <p onClick={() => setByQty(1)}>{primaryCoin?.symbol}</p>
              </a>
            </li>
            <li>
              <a className="dropdown-item">
                <p onClick={() => setByQty(0)}>{secondaryCoin?.symbol}</p>
              </a>
            </li>
          </ul>
        </div>
      </InputGroup>

      <div className="text-red my-2">{error === '' ? '' : error}</div>
      
      <Slider
        min={0}
        step={0.1}
        marks={percentageMarks}
        style={sliderStyles}
        disabled={isBtnDisabled}
        defaultValue={[0, 25, 50, 75, 100]}
        value={percentage.replace('%', '')}
        // eslint-disable-next-line react/jsx-no-bind
        onChange={rangeValue}
        className="mb-4 sliderTrade"
      />
      <InputGroup className="mb-2 text-white-light d-flex align-items-center">
        <div style={{ paddingTop: '2px' }}>
          <input
            type="checkbox"
            checked={TPSL}
            onChange={() => {
              setTPSL(!TPSL);
              setTP('');
              setSL('');
            }}
            style={{ minWidth: '100%' }}
          />
        </div>
        <p style={{ margin: '0', paddingLeft: '5px', fontSize: '11px' }}>TP/SL</p>
      </InputGroup>
      {TPSL ? (
        <>
          <InputGroup className="mb-3">
            <FormControl
              type="number"
              min="0"
              value={TP}
              onChange={(e) => setTP(e.target.value)}
            />
            <InputGroup.Text id="basic-addon2">
              <span style={{ color: 'rgba(255, 255, 255, 0.46)', fontSize: '12px', marginRight: '5px' }}>{t('labels.take_profit')}</span>
              {secondaryCoin?.symbol}
            </InputGroup.Text>
          </InputGroup>
          <InputGroup className="mb-3">
            <FormControl
              type="number"
              min="0"
              value={SL}
              onChange={(e) => setSL(e.target.value)}
            />
            <InputGroup.Text id="basic-addon2">
              <span style={{ color: 'rgba(255, 255, 255, 0.46)', fontSize: '12px', marginRight: '5px' }}>{t('labels.stop_loss')}</span>
              {secondaryCoin?.symbol}
            </InputGroup.Text>
          </InputGroup>
        </>
      ) : null}

      <div className="d-flex justify-content-between order-value--usdt">
        <div>
          <p className="text-white-light">{`${t('labels.order_value')}:`}</p>
          <p style={{ fontSize: 'smaller' }}>
            {`${calculateSecondaryOrderValue} ${
              secondaryCoin?.symbol}`}
          </p>
        </div>
        <div>
          <p className="text-white-light">{`${t('labels.order_value')}:`}</p>
          <p style={{ fontSize: 'smaller' }}>
            {`${calculatePrimaryOrderValue} ${primaryCoin?.symbol}`}
          </p>
        </div>
      </div>

      {token ? (
        <div className="d-flex justify-content-between">
          {toggle === 1 ? (
            <button
              type="button"
              className="btn open-long-btn"
              onClick={() => {
                handleShowConfirmation();
                if (market === 1) setRate(true);
              }}
            >
              {t('labels.buy_long')}
              <p className="text-white-light">{`${toFixed(coinAmountCost, 2)} ${secondaryCoin?.symbol}`}</p>
            </button>
          ) : (
            <button
              type="button"
              className="btn open-short-btn"
              onClick={() => {
                handleShowConfirmation();
                if (market === 1) setRate(true);
              }}
            >
              {t('labels.sell_short')}
              <p className="text-white-light">{`${toFixed(coinAmountCost, 2)} ${secondaryCoin.symbol}`}</p>
            </button>
          )}
        </div>
      ) : (
        <div className=" buy-tabs">
          <Link to="/register">
            <button type="button" className="mb-2 register-now">
              {t('labels.register_now')}
            </button>
          </Link>
          <Link to="/login">
            <button type="button" className="login-now">
              {t('labels.login')}
            </button>
          </Link>
        </div>
      )}

      <div>
        <div className="d-flex justify-content-between mt-5">
          <p>{t('labels.assets')}</p>
          <WalletTransfer currencies={currencyData} />
        </div>
        {token ? (
          <>
            {/* { totalBalance = secAvbl +  primAvbl } */}
            <div className="d-flex justify-content-between">
              <p className="text-white-light">{t('portfolio.total_balance')}</p>
              <p>{`${toFixed(totalBalance, 2)} ${secondaryCoin.symbol}`}</p>
            </div>
            <div className="d-flex justify-content-between">
              <p className="text-white-light">{t('labels.futures_balance')}</p>
              <p>{`${toFixed(secAvbl, 2)} ${secondaryCoin.symbol}`}</p>
            </div>
            <div className="d-flex justify-content-between">
              <p className="text-white-light">{t('labels.available_credits')}</p>
              <p>{`${toFixed(totalAvailableCredit, 2)} ${secondaryCoin.symbol}`}</p>
            </div>
            <div className="d-flex justify-content-between">
              <p className="text-white-light">{t('labels.total_credits')}</p>
              <p>{`${toFixed(-previousTotalCredit, 2)} ${secondaryCoin.symbol}`}</p>
            </div>
          </>
        ) : null}
      </div>

      <div className="deposit-buy-exchange-btns">
        <Link to={`/deposit/${primaryCoin?.symbol}`}>
          <button type="button" className="mb-1 btn text-white-light">
            {t('labels.deposit')}
          </button>
        </Link>
        <button type="button" className="mb-1 btn text-white-light">
          <WalletTransfer currencies={currencyData} />
        </button>
        {isWithdrawalEnabled && (
          <Link to={`/withdraw-crypto/${primaryCoin?.symbol}`}>
            <button type="button" className="mb-1 btn text-white-light">
              {t('labels.withdrawal')}
            </button>
          </Link>
        )}
      </div>
    </div>
  );


  return (
    <>
      <section className="header-padding">
        {userId ? <GetAccountData /> : null}
        {loader ? (
          <FullPageLoader />
        ) : (
          <div className="trade-page starbitrex">
            <TradePriceWidget
              coinsToPrecisions={coinsToPrecisions}
              ticketData={ticketPrice}
              primaryCoin={primaryCoin}
              secondaryCoin={secondaryCoin}
              setPrimaryCoin={(d) => setPrimaryCoin(d)}
              setSecondaryCoin={(d) => setSecondaryCoin(d)}
              unsubscribeFromPrevSocket={unsubscribeFromPrevSocket}
              today={today}
              prevPair={pairName}
              pairs={coins}
              currencyData={currencyData}
              coinLink="trade"
            />
            <Container fluid>
              <div className="trade-page-crypto">
                <div className="graph-orders-table-wrapper">
                  <div className="graph-and-table-wrapper">
                    { chartComponent }
                    {isMobile ? (
                      <div className="buy-and-sell">
                        <div className="flex-row mb-2 text-white d-flex justify-content-between margin-x">
                          <div className="dropdown w-100 buy-and-sell-dd">
                            <button
                              onClick={() => handleShowLeverage()}
                              className="btn text-white dropdown-toggle w-100 d-flex justify-content-between align-items-center"
                              type="button"
                              disabled={isLeverageLoading}
                              id="dropdownMenuButton1"
                              data-bs-toggle="dropdown"
                              aria-expanded="false"
                            >
                              <div className="d-flex">
                                <p
                                  className="mb-0 text-white-light pe-2"
                                  style={{ borderRight: '1px solid rgba(255, 255, 255, 0.46)' }}
                                >
                                  {margin === MarginType.CROSS ? `${t('labels.cross')}` : `${t('labels.isolated')}`}
                                </p>
                                <p
                                  className="mb-0 ms-2"
                                  style={{
                                    display: 'flex',
                                    justifyContent: 'center',
                                    alignItems: 'center',
                                  }}
                                >
                                  { !isLeverageLoading ? `${leverage}x` : <div className="loading-dots" style={{ marginLeft: '10px' }} /> }
                                </p>
                              </div>
                            </button>
                          </div>
                        </div>
                        <div className="buy-sell-tabs">
                          <div className="mb-2 text-white d-flex buy-sell">
                            <div
                              className={toggle ? 'point bg-green-tab' : 'point'}
                              onClick={() => {
                                setToggle(1);
                                setMarket(0);
                                setCoinAmountQty(0);
                                setCoinAmountCost(0);
                                setPercentage(`${0}%`);
                                setError('');
                              }}
                            >
                              <span>{t('labels.buy')}</span>
                            </div>
                            <div
                              className={!toggle ? 'point bg-red-tab' : 'point'}
                              onClick={() => {
                                setToggle(0);
                                setMarket(0);
                                setCoinAmountQty(0);
                                setCoinAmountCost(0);
                                setPercentage(`${0}%`);
                                setError('');
                              }}
                            >
                              <span>{t('labels.sell')}</span>
                            </div>
                          </div>
                          {ordertabs}
                        </div>
                      </div>
                    ) : (
                      <Order
                        coinsToPrecisions={coinsToPrecisions}
                        primaryCoin={primaryCoin}
                        secondaryCoin={secondaryCoin}
                        futureTradesAPI={futureTradesAPI}
                        orderBookAPI={orderBookAPI}
                        today={today}
                        selectRate={(selectedRate) => {
                          setBuyingRate(parseFloat(selectedRate));
                          setSellingRate(parseFloat(selectedRate));
                        }}
                      />
                    )}
                  </div>
                  <div className="graph-table">
                    <TradeOrdersDatatables
                      style={{ whitespace: 'normal' }}
                      rates={
                          orderBookAPI && orderBookAPI.b && orderBookAPI.b.length
                            ? currentMarketPrice
                            : 0
                        }
                      lowestBuyRate={
                          orderBookAPI && orderBookAPI.b && orderBookAPI.b.length
                            ? currentMarketPrice
                            : 0
                        }
                      highestSellRate={
                          orderBookAPI && orderBookAPI.a && orderBookAPI.a.length
                            ? currentMarketPrice
                            : 0
                        }
                      selectedRow={selectedRow}
                      amounts={amounts}
                      token={token}
                      avbl={initAvbl}
                      updateAvbl={(val) => setSecAvbl(val)}
                      currencyData={currencyData}
                      userOrders={userOrders}
                      setSelectedRow={(d) => setSelectedRow(d)}
                      setTP={(d) => setTP(d)}
                      setSL={(d) => setSL(d)}
                      setLoader={(d) => setLoader(d)}
                      setTrailingPrice={(d) => setTrailingPrice(d)}
                      trailingPrice={trailingPrice}
                      TP={TP}
                      SL={SL}
                    />
                  </div>
                </div>

                {!isMobile ? (
                  <div className="buy-and-sell">
                    <div className="flex-row mb-2 text-white d-flex justify-content-between margin-x">
                      <div className="dropdown w-100 buy-and-sell-dd">
                        <button
                          onClick={() => handleShowLeverage()}
                          className="btn text-white dropdown-toggle w-100 d-flex justify-content-between align-items-center"
                          type="button"
                          disabled={isLeverageLoading}
                          id="dropdownMenuButton1"
                          data-bs-toggle="dropdown"
                          aria-expanded="false"
                        >
                          <div className="d-flex">
                            <p
                              className="mb-0 text-white-light pe-2"
                              style={{ borderRight: '1px solid rgba(255, 255, 255, 0.46)' }}
                            >
                              {margin === MarginType.CROSS ? `${t('labels.cross')}` : `${t('labels.isolated')}`}
                            </p>
                            <p
                              className="mb-0 ms-2"
                              style={{
                                display: 'flex',
                                justifyContent: 'center',
                                alignItems: 'center',
                              }}
                            >
                              { !isLeverageLoading ? `${leverage}x` : <div className="loading-dots" style={{ marginLeft: '10px' }} /> }
                            </p>
                          </div>
                        </button>
                      </div>
                    </div>
                    <div className="buy-sell-tabs">
                      <div className="mb-2 text-white d-flex buy-sell">
                        <div
                          className={toggle ? 'point bg-green-tab' : 'point'}
                          onClick={() => {
                            setToggle(1);
                            setMarket(0);
                            setCoinAmountQty(0);
                            setCoinAmountCost(0);
                            setPercentage(`${0}%`);
                            setError('');
                          }}
                        >
                          <span>{t('labels.buy')}</span>
                        </div>
                        <div
                          className={!toggle ? 'point bg-red-tab' : 'point'}
                          onClick={() => {
                            setToggle(0);
                            setMarket(0);
                            setCoinAmountQty(0);
                            setCoinAmountCost(0);
                            setPercentage(`${0}%`);
                            setError('');
                          }}
                        >
                          <span>{t('labels.sell')}</span>
                        </div>
                      </div>
                      {ordertabs}
                    </div>
                  </div>
                ) : (
                  <Order
                    coinsToPrecisions={coinsToPrecisions}
                    primaryCoin={primaryCoin}
                    secondaryCoin={secondaryCoin}
                    futureTradesAPI={futureTradesAPI}
                    orderBookAPI={orderBookAPI}
                    today={today}
                    selectRate={(selectedRate) => {
                      setBuyingRate(parseFloat(selectedRate));
                      setSellingRate(parseFloat(selectedRate));
                    }}
                  />
                )}
                
              </div>
            </Container>
            { memoizedSetLeverageModal }
            <div className="batton" />
          </div>
        )}
      </section>
      <Modal
        className="withdraw-details two-factor-auth text-center"
        centered
        backdrop="static"
        show={showConfirmation}
        onHide={handleCloseConfirmation}
      >
        <Modal.Header className="modal-main-heading" closeButton />
        <Modal.Body className="text-white">
          <>
            <div className="d-flex justify-content-between">
              <p>{t('labels.order_price')}</p>
              <p>
                <b>{toggle === 1 ? toFixed(buyingRate, 2) : toFixed(sellingRate, 2)}</b>
                {secondaryCoin?.symbol}
              </p>
            </div>
            <div className="d-flex justify-content-between">
              <p>{t('labels.quantity')}</p>
              <p>
                <b>{calculatePrimaryOrderValue ? parseFloat(calculatePrimaryOrderValue).toFixed(2) : 0}</b>
                {primaryCoin?.symbol}
              </p>
            </div>
            <div className="d-flex justify-content-between">
              <p>{t('labels.order_value')}</p>
              <p>
                <b>{calculateSecondaryOrderValue ? parseFloat(calculateSecondaryOrderValue) : 0}</b>
                {secondaryCoin.symbol}
              </p>
            </div>
            <br />
          </>
          <div className="limit-modal-btns">
            <button
              type="button"
              onClick={() => {
                handleSubmit();
              }}
              className="btn confirm"
            >
              {t('labels.confirm')}
            </button>
            <button type="button" onClick={handleCloseConfirmation} className="btn cancel">
              {t('labels.cancel')}
            </button>
          </div>
        </Modal.Body>
      </Modal>
    </>
  );
}

export default TradeInfo;
