import React from 'react';
import ReactDOM from 'react-dom';
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import 'bootstrap/dist/css/bootstrap.min.css';
// eslint-disable-next-line import/extensions
import 'bootstrap/dist/js/bootstrap.min.js';
import './assets/css/starbitrex.css';
import './assets/css/newstarbitrex.css';
import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';
import NotFound from './pages/NotFound';
import routes from './routes';

function App() {
  return (
    <Router>
      <Routes>
        {routes.map((route, index = 0) => (
          <Route
            exact={route.exact}
            path={route.path}
            element={(
              <route.layout title={route.name}>
                <route.component />
              </route.layout>
            )}
            key={index}
          />
        ))}

        <Route path="*" element={<NotFound />} />
      </Routes>
    </Router>
  );
}

export default App;

if (document.getElementById('app')) {
  ReactDOM.render(<App />, document.getElementById('app'));
}
