import React from 'react';
import ReactDOM from 'react-dom';
import './index.css';
import { Provider } from 'react-redux';
import { ToastContainer } from 'react-toastify';
import { ChatProvider, ModalProvider } from './context';
import reportWebVitals from './reportWebVitals';
import store from './redux/combineReducers';
import 'react-toastify/dist/ReactToastify.css';
import './i18n';
import App from './App';

ReactDOM.render(
  <Provider store={store}>
    <ToastContainer />
    <React.StrictMode>
      <ModalProvider>
        <ChatProvider>
          <App />
        </ChatProvider>
      </ModalProvider>
    </React.StrictMode>
  </Provider>,
  document.getElementById('root'),
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
