import React, { useState, useMemo } from 'react';
import {
  Modal, ModalBody, ModalFooter, ModalHeader, 
} from 'react-bootstrap';
import { ModalContext } from './ModalContext';

export function ModalProvider({ children }) {
  const [isModalShown, setShowModal] = useState(false);
  const [headerContent, setHeaderContent] = useState(); 
  const [bodyContent, setBodyContent] = useState(); 
  const [footerContent, setFooterContent] = useState();
  const [classStyle, setClassStyle] = useState('context-modal');

  const showModal = ({
    headerContent, bodyContent, footerContent, classStyle, 
  }) => {
    setShowModal(true);
    if (headerContent) setHeaderContent(headerContent);
    if (bodyContent) setBodyContent(bodyContent);
    if (footerContent) setFooterContent(footerContent);
    if (classStyle) setClassStyle(classStyle);
  };

  const hideModal = () => {
    setShowModal(false);
  };

  const valueProvider = useMemo(() => ({
    showModal,
    hideModal,
  }), []);

  return (
    <ModalContext.Provider value={valueProvider}>
      {isModalShown && (
        <Modal
          className={classStyle}
          show={isModalShown}
          centered
          onHide={setShowModal}
        >
          {headerContent && (
            <ModalHeader closeButton>
              {headerContent}
            </ModalHeader>
          )}
          
          {bodyContent && (
            <ModalBody>
              {bodyContent}
            </ModalBody>
          )}

          {footerContent && (
            <ModalFooter>
              {footerContent}
            </ModalFooter>
          )}
        </Modal>
      )}
      { children }
    </ModalContext.Provider>
  );
}
