import React from 'react';
import { Link } from 'react-router-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faPencil } from '@fortawesome/free-solid-svg-icons';
import { toFixed } from '../helpers/utils';
import { TradeType } from '../types/order';

export const orderHistoryColumnsSchema = (t) => {
  const orderHistoryColumns = [
    {
      name: t('labels.contracts'),
      selector: (row) => (
        <>
          <b>
            {row.pairName}
          </b>
          <div>
            {row.marginType === 0 ? t('labels.cross') : t('labels.isolated')}
            <span className={row.tradeType === TradeType.LONG ? 'text-green' : 'text-red'}>
              {row.leverage}
              x
            </span>
          </div>
        </>
      ),
      sortable: true,
    },
    {
      name: t('labels.filled_total'),
      selector: (row) => `${(row.marketOrder !== '1' && row.triggered === true) ? parseFloat(row.qty).toFixed(2) : '0.00'
      }/${
        parseFloat(row.qty).toFixed(2)} ${row?.toCurrency?.symbol}`,
      sortable: true,
    },
    {
      name: t('labels.order_price'),
      selector: (row) => `${parseFloat(row.tradeStartPrice).toFixed(2)} ${row?.fromCurrency?.symbol}`,
      sortable: true,
    },
    {
      name: t('labels.trigger_price'),
      selector: (row) => ((row.marketOrder !== '1' && row.triggered === true) ? `${parseFloat(row.tradeStartPrice).toFixed(2)} ${row?.fromCurrency?.symbol}` : '-'),
      sortable: true,
    },
    {
      name: t('labels.liq_price'),
      selector: (row) => (
        <>
          <span id={`${row?._id}liq`}>
            {toFixed(row.tradeEndPrice, 2)}
          </span>
          <span>
            {` ${row?.fromCurrency?.symbol}`}
          </span>
        </>
      ),
      sortable: true,
    },
    {
      name: t('labels.exit_price'),
      selector: (row) => (row.exitPrice ? `${parseFloat(row.exitPrice).toFixed(2)} ${row?.fromCurrency?.symbol}` : '-'),
      sortable: true,
    },

    {
      name: t('labels.realized_pnl'),
      selector: (row) => (
        <span className={row.tradeProfitOrLoss >= 0 ? 'text-green' : 'text-red'}>
          {parseFloat(row.tradeProfitOrLoss).toFixed(2)}
        </span>
      ),
      sortable: true,
    },

    {
      name: t('labels.trade_type'),
      selector: (row) => (row.tradeType === TradeType.LONG ? t('labels.buy') : t('labels.sell')),
      sortable: true,
    },
    {
      name: t('labels.order_type'),
      selector: (row) => (row.marketOrder === 1 ? t('labels.market') : t('labels.limit')),
      sortable: true,
    },
    {
      name: t('labels.commission'),
      selector: ({ endCommission }) => toFixed(endCommission, 3),
      sortable: true,
    },
    {
      name: 'Swap',
      cell: ({ endSwap }) => toFixed(endSwap, 2),
    },
    {
      name: t('labels.status'),
      selector: (row) => (row.status === 1 ? t('labels.processing')
        : row.status === 2 ? t('labels.completed')
          : row.status === 3 ? t('labels.stopped')
            : row.status === 4 ? t('labels.liquidate')
              : t('labels.created')),
      sortable: true,
    },
  ];

  return orderHistoryColumns;
};

export const accountColumnsSchema = (currencyData, getCoinImg, t) => {
  const accountColumns = [
    {
      name: t('labels.symbol'),
      selector: (coin) => currencyData?.find(
        (row) => row._id === coin.currencyId,
      )?.symbol,
      cell: (coin) => {
        const coinSymbol = currencyData?.find(
          (row) => row._id === coin.currencyId,
        )?.symbol;
        return (
          <Link className="portfilio-link" to={`/activity/${coinSymbol}`}>
            <div className="portfolio-coin d-flex align-items-center">
              <img src={getCoinImg(coinSymbol)} alt="btc" className="img-fluid me-2 coin-img p-2" />
              <div>
                <p className="name m-0">
                  {currencyData?.find(
                    (row) => row._id === coin.currencyId,
                  )?.name}

                </p>
                <p className="symbol m-0">{coinSymbol}</p>
              </div>
            </div>
          </Link>
        );
      },
      sortable: true,
    },
    {
      name: t('portfolio.spot_wallet_amount'),
      selector: (coin) => parseFloat(coin.amount).toFixed(2),
      sortable: true,
    },
    {
      name: t('portfolio.futures_wallet_amount'),
      selector: (coin) => parseFloat(coin.futures_amount).toFixed(2),
      sortable: true,
    },
  ];
  return accountColumns;
};

export const pendingOrdersColumnsSchema = (
  rates,
  setCoinAmount,
  setSelectedRow,
  setOrderRate,
  handleShowLimit,
  handleShowMarket,
  setStopRate,
  setOrderId,
  handleShow,
  t,
) => {
  const handleClose = (row) => {
    const currentRates = rates.find((line) => line.symbol === row.pairName)?.markPrice;
    setStopRate(rates ? rates.find((line) => line.symbol === row.pairName)?.markPrice : 0); 
    setOrderId(row?._id); 
    handleShow();
  };
  const pendingOrdersColumns = [
    {
      name: t('labels.contracts'),
      selector: (row) => (
        <>
          <b>
            {row.pairName}
          </b>
          <div>
            {row.marginType === 0 ? t('labels.cross') : t('labels.isolated')}
            <span className={row.tradeType === TradeType.LONG ? 'text-green' : 'text-red'}>
              {row.leverage}
              x
            </span>
          </div>
        </>
      ),
      sortable: true,
    },
    {
      name: t('labels.qty'),
      selector: (row) => `${parseFloat(row.qty).toFixed(2)} ${row?.toCurrency?.symbol}`,
      sortable: true,
    },
    {
      name: t('labels.value'),
      selector: (row) => `${parseFloat(parseFloat(row.qty) * parseFloat(row.tradeStartPrice)).toFixed(2)} ${row?.fromCurrency?.symbol}`,
      sortable: true,
    },
    {
      name: t('labels.entry_price'),
      selector: (row) => `${parseFloat(row.tradeStartPrice).toFixed(2)} ${row?.fromCurrency?.symbol}`,
      sortable: true,
    },
    {
      name: t('labels.mark_price'),
      selector: (row) => `${rates ? parseFloat(rates?.find((line) => line.symbol === row?.pairName).markPrice).toFixed(2) : 0} ${row?.fromCurrency?.symbol}`,
      sortable: true,
    },
    {
      name: t('labels.liq_price'),
      selector: (row) => (
        <>
          <span id={`${row?._id}liq`}>
            {toFixed(row.tradeEndPrice, 2)}
          </span>
          <span>
            {` ${row?.fromCurrency?.symbol}`}
          </span>
        </>
      ),
      sortable: true,
    },

    {
      name: t('labels.close_by'),
      selector: (row) => (
        <div className="d-flex">
          <button
            type="button"
            onClick={() => { setCoinAmount(parseFloat(row.qty) / parseFloat(row.leverage)); setSelectedRow(row); setOrderRate(row.tradeStartPrice); handleShowMarket(); }}
            className="btn graph-table-btns me-2"
          >
            {t('labels.market')}
          </button>
          <button
            type="button"
            onClick={() => handleClose(row)}
            className="btn graph-table-btns"
          >
            {t('labels.stop')}
          </button>
        </div>
      ),
      sortable: true,
    },
  ];
  return pendingOrdersColumns;
};

export const openOrdersColumnsSchema = (
  rates,
  getPositionMargin,
  setRate,
  getUnrealizedPnL,
  setSelectedRow,
  handleShowTPSL,
  setTP,
  setSL,
  handleShowTrailing,
  setTrailingPrice,
  setStopRate,
  setOrderId,
  handleShow,
  t,
) => {
  const handleClose = (row) => {
    setStopRate(rates ? rates?.find((line) => line.symbol === row.pairName).markPrice : 0); 
    setOrderId(row?._id); 
    handleShow();
  };
  const openOrdersColumns = [
    {
      name: t('labels.contracts'),
      selector: (row) => (
        <>
          <b>
            {row.pairName}
          </b>
          <div>
            {row.marginType === 0 ? t('labels.cross') : t('labels.isolated')}
            <span className={row.tradeType === TradeType.LONG ? 'text-green' : 'text-red'}>
              {row.leverage}
              x
            </span>
          </div>
        </>
      ),
      sortable: true,
    },
    {
      name: t('labels.qty'),
      selector: (row) => `${parseFloat(row.qty).toFixed(2)} ${row?.toCurrency?.symbol}`,
      sortable: true,
    },
    {
      name: t('labels.value'),
      selector: (row) => `${parseFloat(parseFloat(row.qty) * parseFloat(row.tradeStartPrice)).toFixed(2)} ${row?.fromCurrency?.symbol}`,
      sortable: true,
    },
    {
      name: t('labels.entry_price'),
      selector: (row) => `${parseFloat(row.tradeStartPrice).toFixed(2)} ${row?.fromCurrency?.symbol}`,
      sortable: true,
    },
    {
      name: t('labels.mark_price'),
      selector: (row) => {
        const asset = rates ? rates.find((line) => line.symbol === row?.pairName) : undefined;

        return asset ? `${parseFloat(asset.markPrice).toFixed(2)} ${row?.fromCurrency?.symbol}` : 0;
      },
      sortable: true,
    },
    {
      name: t('labels.liq_price'),
      selector: (row) => (
        <>
          <span id={`${row?._id}liq`}>
            {toFixed(row.tradeEndPrice, 2)}
          </span>
          <span>
            {` ${row?.fromCurrency?.symbol}`}
          </span>
        </>
      ),
      sortable: true,
    },
    {
      name: t('labels.position_margin'),
      selector: (row) => getPositionMargin(row),
      sortable: true,
    },
    {
      name: t('labels.commission'),
      selector: ({ commission = 0, userInvestedAmount = 0 }) => toFixed(((userInvestedAmount * commission ?? 0) / 100), 2),
      sortable: true,
    },
    {
      name: 'Swap',
      cell: ({ endSwap }) => toFixed(endSwap, 2),
    },
    {
      name: t('labels.unrealized_pnl'),
      selector: (row) => {
        const pnLResult = getUnrealizedPnL(row);
        setRate(parseFloat(pnLResult.value / (row.tradeType === TradeType.LONG ? parseFloat(row.userInvestedAmount) : (parseFloat(row.userInvestedAmount) * parseFloat(row.tradeStartPrice)))).toFixed(4));

        return rates ? (
          <span className={`${pnLResult.value >= 0 ? 'text-green' : 'text-red'} d-flex flex-column`}>
            <span id={`${row?._id}pnl`}>
              {`${toFixed(pnLResult.value, 2)}  ${row.fromCurrency.symbol} (${toFixed(pnLResult.percentage, 2)}%)`}
            </span>
          </span>
        ) : '';
      },
    },
    {
      name: t('labels.roe'),
      selector: (row) => {
        const pnLResult = getUnrealizedPnL(row);
        return rates ? (
          <span className={`${pnLResult.roe >= 0 ? 'text-green' : 'text-red'} d-flex flex-column`}>
            <span id={`${row?._id}roe`}>
              {`${pnLResult.roe.toFixed(2)}%`}
            </span>
          </span>
        ) : '';
      },
    },
    {
      name: t('labels.tpsl'),
      selector: (row) => (
        <span>
          {row.tpsl
            ? (
              <>
                {row.takeProfitPrice ? `${row.takeProfitPrice} ${row?.fromCurrency?.symbol}` : '-'}
                /
                {row.stopLossPrice ? `${row.stopLossPrice} ${row?.fromCurrency?.symbol}` : '-'}
                <button type="button" onClick={() => { setSelectedRow(row); handleShowTPSL(); setTP(row.takeProfitPrice); setSL(row.stopLossPrice); }} className="btn graph-table-btns ms-2">
                  <FontAwesomeIcon icon={faPencil} className="header-icon" />
                </button>

              </>
            )
            : (
              <button
                type="button"
                onClick={() => {
                  setSelectedRow(row); handleShowTPSL();
                }}
                className="btn graph-table-btns"
              >
                {`+ ${t('labels.add')}`}
              </button>
            )}
        </span>
      ),
      sortable: true,
    },
    {
      name: t('labels.trailing_stop'),
      selector: (row) => (
        <span>
          {row.tradeTrailingPrice
            ? (
              <>
                {row.tradeTrailingPrice ? `${row.tradeTrailingPrice} ${row?.fromCurrency?.symbol}` : '-'}
                <button type="button" onClick={() => { setSelectedRow(row); handleShowTrailing(); setTrailingPrice(row.tradeTrailingPrice); }} className="btn graph-table-btns ms-2">
                  <FontAwesomeIcon icon={faPencil} className="header-icon" />
                </button>

              </>
            )
            : (
              <button
                type="button"
                onClick={() => {
                  setSelectedRow(row); handleShowTrailing();
                }}
                className="btn graph-table-btns"
              >
                {`+ ${t('labels.add')}`}
              </button>
            )}
        </span>
      ),
      sortable: true,
    },
    {
      name: t('labels.close_by'),
      selector: (row) => {
        const pnLResult = getUnrealizedPnL(row);
        
        if (!pnLResult.value) return;

        return (
          <div className="d-flex">
            <button
              type="button"
              onClick={() => handleClose(row)}
              className="btn graph-table-btns me-2"
              disabled={!rates?.length}
            >
              {t('labels.stop')}
            </button>
          </div>
        );
      },
      sortable: true,
    },
  ];
  return openOrdersColumns;
};
